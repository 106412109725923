<template>
  <edit-row-button
    :model="props.model"
    :components="['sales-rep-modal-table-button']"
    :delete-modal-info="deleteModalInfo"
    scrollable="clientMenuButtonParent"
    class="flex justify-end"
  />
</template>

<script setup lang="ts">
import type { IUser } from "@/models/users";
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "@/stores/users";

const { t } = useI18n();
const usersStore = useUsersStore();

const props = defineProps({
  model: {
    type: Object as PropType<IUser>,
    required: true
  }
});

const deleteRepresentative = () => {
  usersStore.deleteSalesRepresentative({ representativeId: props.model.id });
};

const deleteModalInfo = {
  title: `${t("COMMON.DELETE")} ${t("COMMON.USER")}`,
  description: `${props.model.full_name} ${t("ORGANIZATION.DELETE_WARNING")}`,
  handler: deleteRepresentative
};
</script>
